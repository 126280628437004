import React from "react";
function Footer() {
  return (
    <>
    <footer class="footer footer-dark">
    <section class="footer-section">
      <div class="container">
        <div class="row">
          <div class="col-md-12 col-lg-4 sm-m-15px-tb md-m-30px-b">
            <h4 class="font-alt">About Us</h4>
            <p class="footer-text">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
            tempor incididunt ut labore et dolore magna aliqua.</p>
            <ul class="social-icons">
              <li><a class="facebook" href="#"><i class="fab fa-facebook-f"></i></a></li>
              <li><a class="twitter" href="#"><i class="fab fa-twitter"></i></a></li>
              <li><a class="google" href="#"><i class="fab fa-google-plus-g"></i></a></li>
              <li><a class="linkedin" href="#"><i class="fab fa-linkedin-in"></i></a></li>
            </ul>
          </div>

          <div class="col-6 col-md-4 col-lg-2 sm-m-15px-tb">
            <h4 class="font-alt">Product</h4>
            <ul class="fot-link">
              <li><a href="#">Features</a></li>
              <li><a href="#">Carrers</a></li>
              <li><a href="#">Awards</a></li>
              <li><a href="#">Users Program</a></li>
              <li><a href="#">Locations</a></li>
            </ul>
          </div>

          <div class="col-6 col-md-4 col-lg-2 sm-m-15px-tb">
            <h4 class="font-alt">Company</h4>
            <ul class="fot-link">
                <li><a href="#">About</a></li>
                <li><a href="#">Blog</a></li>
                <li><a href="#">Press</a></li>
                <li><a href="#">Policy</a></li>
                <li><a href="#">Contact</a></li>
              </ul>
          </div>

          <div class="col-md-4 col-lg-4 sm-m-15px-tb">
            <h4 class="font-alt">Subscribe</h4>
            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p>
            <div class="subscribe-box m-20px-t">
              <input placeholder="Enter Email Id" class="form-control" type="text" name="demo"/>
              <button class="btn btn-theme"><i class="ti-arrow-right"></i></button>
            </div>
          </div>

        </div>
        
        <div class="footer-copy">
          <div class="row">
            <div class="col-12">
              <p>All © Copyright by . All Rights Reserved.</p>
            </div>
          </div>
        </div>

      </div>   
    </section>
  </footer>
    </>
  );
}

export default Footer;
