import React from "react";
function Content() {
  return (
    <>
<main>
<section id="home" class="home-banner-01 bg-effect-box">
  <div class="bg-effect bg-cover bg-fixed" style={{backgroundImage: "url('static/img/banner-effect-3.svg')"}}></div>
  <div class="container">
    <div class="row align-items-center justify-content-center p-10px-tb md-p-10px-b">

      <div id="carouselExampleIndicators" 
      class="carousel slide" 
      data-ride="carousel"
      data-interval="3000">
        <div class="carousel-inner">
          <div class="carousel-item active">
            <div class="bg-effect bg-cover bg-fixed" style={{backgroundImage: "url('static/img/banner-effect-3.svg')"}}></div>
            <div class="container">
              <div class="row align-items-center justify-content-center">
                <div class="col-lg-5 text-banner order-last order-md-first">
                  <h5 class="theme-after dark-color">Databox</h5>
                  <h1 class="font-alt dark-color">Design Agency</h1>
                  <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                  <div class="subscribe-block">
                    <div class="form-group">
                      <input class="form-control" type="email" placeholder="Your email" name="Name" />
                      <span class="input-focus-effect theme-bg"></span>
                      <button class="btn-theme btn">Get Updates</button>
                    </div>
                    <label>30-day FREE trial - no credit card needed</label>
                  </div>
                </div>
                <div class="col-lg-7 text-center m-50px-t order-first order-md-last">
                  <img height="400" src="static/img/home-banner-7.svg" title="" alt="" />
                </div>
              </div>
            </div>
          </div>

          <div class="carousel-item p-20px-tb md-p-20px-b">
            <div class="bg-effect bg-cover bg-fixed" style={{backgroundImage: "url('static/img/banner-effect-6.svg')"}}></div>
            <div class="container">
              <div class="row align-items-center justify-content-center ">
                <div class="col-lg-5 order-last order-md-first text-banner">
                  <h5 class="theme-after dark-color">Databox</h5>
                  <h1 class="font-alt dark-color">Design Agency</h1>
                  <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                  <div class="subscribe-block">
                    <div class="form-group">
                      <input class="form-control" type="email" placeholder="Your email" name="Name" />
                      <span class="input-focus-effect theme-bg"></span>
                      <button class="btn-theme btn">Get Updates</button>
                    </div>
                    <label>30-day FREE trial - no credit card needed</label>
                  </div>
                </div>
                <div class="col-lg-7 text-center">
                  <img height="400" src="static/img/home-banner-6.svg" title="" alt="" />
                </div>
              </div> 
            </div>
          </div>

          <div class="carousel-item p-80px-tb md-p-80px-b">
            <div class="bg-effect bg-cover bg-fixed" style={{backgroundImage: "url('static/img/banner-effect-4.svg')"}}></div>
            <div class="container">
              <div class="row align-items-center justify-content-center">
                <div class="col-lg-5 order-last order-md-first text-banner">
                  <h5 class="theme-after dark-color">Databox</h5>
                  <h1 class="font-alt dark-color">Design Agency</h1>
                  <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                  <div class="subscribe-block">
                    <div class="form-group">
                      <input class="form-control" type="email" placeholder="Your email" name="Name" />
                      <span class="input-focus-effect theme-bg"></span>
                      <button class="btn-theme btn">Get Updates</button>
                    </div>
                    <label>30-day FREE trial - no credit card needed</label>
                  </div>
                </div>
                <div class="col-lg-7 text-center">
                  <img height="300" src="static/img/home-banner-3.svg" title="" alt="" />
                </div>
              </div>
            </div>
          </div>

          <div class="carousel-item p-80px-tb md-p-80px-b">
            <div class="bg-effect bg-cover bg-fixed" style={{backgroundImage: "url('static/img/banner-effect-9.svg')"}}></div>
            <div class="container">
              <div class="row align-items-center justify-content-center">
                <div class="col-lg-5 order-last order-md-first text-banner">
                  <h5 class="theme-after dark-color">Databox</h5>
                  <h1 class="font-alt dark-color">Design Agency</h1>
                  <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                  <div class="subscribe-block">
                    <div class="form-group">
                      <input class="form-control" type="email" placeholder="Your email" name="Name"/>
                      <span class="input-focus-effect theme-bg"></span>
                      <button class="btn-theme btn">Get Updates</button>
                    </div>
                    <label>30-day FREE trial - no credit card needed</label>
                  </div>
                </div>
                <div class="col-lg-7 text-center">
                  <img height="300" src="static/img/home-banner-5.svg" title="" alt=""/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>


<section id="services" class="section gray-bg">
  <div class="container">
    <div class="row justify-content-center m-45px-b sm-m-25px-b">
      <div class="col-lg-8 col-md-10">
        <div class="section-title text-center">
          <h6 class="theme-color">Nos services</h6>
          <p>Nous offrons des solutions simples et accessibles à tous, résolvant les problèmes informatiques et technologiques au quotidien.</p>
        </div>
      </div>
    </div>

      <div class="row">
        <div class="col-lg-4 col-md-6 m-15px-tb">
          <div class="feature-box-02 text-center">
            <div class="icon theme-after">
              <i class="ti-check-box theme-bg"></i>
            </div>
            <div class="feature-content">
              <h5 class="font-alt dark-color">Développement Web</h5>
              <p>Lorem ipsum dolor sit amet, gravida nonna maecenas veritatis vestum.</p>
            </div>
          </div>
        </div>

        <div class="col-lg-4 col-md-6 m-15px-tb">
          <div class="feature-box-02 text-center">
            <div class="icon theme-after">
              <i class="ti-rocket theme-bg"></i>
            </div>
            <div class="feature-content">
              <h5 class="font-alt dark-color">Développement Mobile</h5>
              <p>Lorem ipsum dolor sit amet, gravida nonna maecenas veritatis vestum.</p>
            </div>
          </div>
        </div>

        <div class="col-lg-4 col-md-6 m-15px-tb">
          <div class="feature-box-02 text-center">
            <div class="icon theme-after">
              <i class="ti-headphone theme-bg"></i>
            </div>
            <div class="feature-content">
              <h5 class="font-alt dark-color">Intégration ERP & Solutions de gestion</h5>
              <p>Lorem ipsum dolor sit amet, gravida nonna maecenas veritatis vestum.</p>
            </div>
          </div>
        </div>

        <div class="col-lg-4 col-md-6 m-15px-tb">
          <div class="feature-box-02 text-center">
            <div class="icon theme-after">
              <i class="ti-flag-alt theme-bg"></i>
            </div>
            <div class="feature-content">
              <h5 class="font-alt dark-color">Graphisme</h5>
              <p>Lorem ipsum dolor sit amet, gravida nonna maecenas veritatis vestum.</p>
            </div>
          </div>
        </div>

        <div class="col-lg-4 col-md-6 m-15px-tb">
          <div class="feature-box-02 text-center">
            <div class="icon theme-after">
              <i class="ti-flag-alt theme-bg"></i>
            </div>
            <div class="feature-content">
              <h5 class="font-alt dark-color">Modules Biométriques</h5>
              <p>Lorem ipsum dolor sit amet, gravida nonna maecenas veritatis vestum.</p>
            </div>
          </div>
        </div>

        <div class="col-lg-4 col-md-6 m-15px-tb">
          <div class="feature-box-02 text-center">
            <div class="icon theme-after">
              <i class="ti-flag-alt theme-bg"></i>
            </div>
            <div class="feature-content">
              <h5 class="font-alt dark-color">Prospection Technique & Commerciale</h5>
              <p>Lorem ipsum dolor sit amet, gravida nonna maecenas veritatis vestum.</p>
            </div>
          </div>
        </div>

      </div> 
  </div> 
</section>

<section id="feature" class="section shapes-section bg-effect-box">
  <div class="bg-effect bg-fixed bg-center-center" style={{backgroundImage: "url('static/img/banner-effect-4.svg')"}}></div>
  <div class="container">
    <div class="row align-items-center p-40px-b">
        <div class="col-md-6">
          <img src="static/img/feature-6.svg" title="" alt=""/>
        </div>
        <div class="col-md-6">
          <div class="std-box p-50px-lr md-p-0px-lr md-m-40px-t">
            <div class="icon yellow-bg-after">
              <i class="ti-rocket yellow-bg"></i>
            </div>
            <h4 class="font-alt dark-color">A faster way to do just about anything</h4>
            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore.</p>
            <ul class="list-type">
              <li><i class="fas fa-check yellow-bg"></i>Sed volutpat mollis dui sollicitudin rutrum.</li>
              <li><i class="fas fa-check yellow-bg"></i>Donec ac nisi non quam cursus.</li>
              <li><i class="fas fa-check yellow-bg"></i>Praesent ornare metus id efficitur.</li>
              <li><i class="fas fa-check yellow-bg"></i>In sed libero id dui sollicitudin semper.</li>
            </ul>
          </div>
        </div>
    </div> 

    <div class="row align-items-center p-40px-t">
        <div class="col-md-6 order-last order-md-first">
          <div class="std-box p-50px-lr md-p-0px-lr md-m-40px-t">
            <div class="icon pink-bg-after">
              <i class="ti-rocket pink-bg"></i>
            </div>
            <h4 class="font-alt dark-color">A faster way to do just about anything</h4>
            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore.</p>
            <ul class="list-type">
              <li><i class="fas fa-check pink-bg"></i>Sed volutpat mollis dui sollicitudin rutrum.</li>
              <li><i class="fas fa-check pink-bg"></i>Donec ac nisi non quam cursus.</li>
              <li><i class="fas fa-check pink-bg"></i>Praesent ornare metus id efficitur.</li>
              <li><i class="fas fa-check pink-bg"></i>In sed libero id dui sollicitudin semper.</li>
            </ul>
          </div>
        </div>
        <div class="col-md-6">
          <img src="static/img/feature-7.svg" title="" alt=""/>
        </div>
    </div> 

  </div>
  <div class="shapes-box">
    <span data-parallax='{"x": 150, "y": -20, "rotateZ":500}'>
        <img src="static/img/fl-shape-1.png" title="" alt=""/>
    </span>
    <span data-parallax='{"x": 250, "y": 150, "rotateZ":500}'>
        <img src="static/img/fl-shape-2.png" title="" alt=""/>
    </span>
    <span data-parallax='{"x": -180, "y": 80, "rotateY":2000}'>
        <img src="static/img/fl-shape-3.png" title="" alt=""/>
    </span>
    <span data-parallax='{"x": -20, "y": 180}'>
        <img src="static/img/fl-shape-4.png" title="" alt=""/>
    </span>
    <span data-parallax='{"x": 300, "y": 70}'>
        <img src="static/img/fl-shape-5.png" title="" alt=""/>
    </span>
    <span data-parallax='{"x": 250, "y": 180, "rotateZ":1500}'>
        <img src="static/img/fl-shape-6.png" title="" alt=""/>
    </span>
    <span data-parallax='{"x": 180, "y": 10, "rotateZ":2000}'>
        <img src="static/img/fl-shape-7.png" title="" alt=""/>
    </span>
    <span data-parallax='{"x": 60, "y": -100}'>
        <img src="static/img/fl-shape-9.png" title="" alt=""/>
    </span>
    <span data-parallax='{"x": -30, "y": 150, "rotateZ":1500}'>
        <img src="static/img/fl-shape-10.png" title="" alt=""/>
    </span>
  </div>
</section>

<section id="price" class="section gray-bg">
  <div class="container">
    <div class="row justify-content-center m-45px-b sm-m-25px-b">
      <div class="col-lg-8 col-md-10">
        <div class="section-title text-center">
          <h6 class="theme-color">Our Price</h6>
          <h2 class="dark-color font-alt">Our Best Package</h2>
          <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.</p>
        </div>
      </div>
    </div>

    <div class="row">
        <div class="col-md-6 col-lg-3 m-15px-tb">
          <div class="price-table-01">
            <div class="price-header">
              <h4 class="price-title font-alt dark-color">Starter</h4>
              <p>For Personal User</p>
              <div class="price-value theme-color"><span>$</span>10.00<span class="mo">/Month</span></div>
            </div>
            <div class="price-body">
              <ul>
                <li>Unlimited bug report</li>
                <li>Unlimited members</li>
                <li>1 year data retention</li>
                <li>Slack integration</li>
              </ul>
            </div>
            <div class="price-footer">
              <a href="#" class="btn btn-theme">Sign Up</a>
            </div>
          </div>
        </div>

        <div class="col-md-6 col-lg-3 m-15px-tb">
          <div class="price-table-01 theme-bg active">
            <div class="price-header">
              <h4 class="price-title font-alt white-color">Starter</h4>
              <p class="white-color-light">For Personal User</p>
              <div class="price-value white-color"><span>$</span>10.00<span class="mo">/Month</span></div>
            </div>
            <div class="price-body">
              <ul class="white-color-light">
                <li>Unlimited bug report</li>
                <li>Unlimited members</li>
                <li>1 year data retention</li>
                <li>Slack integration</li>
              </ul>
            </div>
            <div class="price-footer">
              <a href="#" class="btn btn-t-white">Sign Up</a>
            </div>
          </div>
        </div>

        <div class="col-md-6 col-lg-3 m-15px-tb">
          <div class="price-table-01">
            <div class="price-header">
              <h4 class="price-title font-alt dark-color">Starter</h4>
              <p>For Personal User</p>
              <div class="price-value theme-color"><span>$</span>10.00<span class="mo">/Month</span></div>
            </div>
            <div class="price-body">
              <ul>
                <li>Unlimited bug report</li>
                <li>Unlimited members</li>
                <li>1 year data retention</li>
                <li>Slack integration</li>
              </ul>
            </div>
            <div class="price-footer">
              <a href="#" class="btn btn-theme">Sign Up</a>
            </div>
          </div>
        </div>

        <div class="col-md-6 col-lg-3 m-15px-tb">
          <div class="price-table-01">
            <div class="price-header">
              <h4 class="price-title font-alt dark-color">Starter</h4>
              <p>For Personal User</p>
              <div class="price-value theme-color"><span>$</span>10.00<span class="mo">/Month</span></div>
            </div>
            <div class="price-body">
              <ul>
                <li>Unlimited bug report</li>
                <li>Unlimited members</li>
                <li>1 year data retention</li>
                <li>Slack integration</li>
              </ul>
            </div>
            <div class="price-footer">
              <a href="#" class="btn btn-theme">Sign Up</a>
            </div>
          </div>
        </div>
    </div>
  </div>
</section>

<section class="section">
  <div class="container">
    <div class="row justify-content-center m-45px-b sm-m-25px-b">
      <div class="col-lg-8 col-md-10">
        <div class="section-title text-center">
          <h6 class="theme-color">Testimonial</h6>
          <h2 class="dark-color font-alt">What Our Client Say!</h2>
          <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.</p>
        </div>
      </div>
    </div>

    <div class="row justify-content-center">
      <div class="col-md-9 col-lg-6">
        <div class="owl-carousel" data-items="1" data-nav-dots="true" data-md-items="1" data-sm-items="1" data-xs-items="1" data-xx-items="1" data-space="0" data-nav-arrow="false">
          <div class="testimonial theme-after">
            <div class="testimonial-info">
              <div class="avtar"><img src="static/img/round1.jpg" title="" alt=""/></div>
              <div class="name">
                <span class="font-alt dark-color">Morgan Cruz</span>
                <label>UX Designer</label>
              </div>
            </div>

            <div class="testimonial-content">
              <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.</p>
            </div>
          </div>
          <div class="testimonial theme-after">
            <div class="testimonial-info">
              <div class="avtar"><img src="static/img/round1.jpg" title="" alt=""/></div>
              <div class="name">
                <span class="font-alt dark-color">Morgan Cruz</span>
                <label>UX Designer</label>
              </div>
            </div>

            <div class="testimonial-content">
              <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.</p>
            </div>
          </div>
        </div>
      </div>

    </div>

    <div class="row justify-content-center m-45px-t sm-m-25px-t">
      <div class="col-sm-6 col-md-3 col-6 col-lg-2 text-center">
        <img src="static/img/logo-4.png" title="" alt=""/>
      </div>
      <div class="col-sm-6 col-md-3 col-6 col-lg-2 text-center">
        <img src="static/img/logo-6.png" title="" alt=""/>
      </div>
      <div class="col-sm-6 col-md-3 col-6 col-lg-2 text-center">
        <img src="static/img/logo-8.png" title="" alt=""/>
      </div>
      <div class="col-sm-6 col-md-3 col-6 col-lg-2 text-center">
        <img src="static/img/logo-1.png" title="" alt="" />
      </div>
    </div>

  </div>
</section>

<section id="blog" class="section gray-bg">
  <div class="container">
    <div class="row justify-content-center m-45px-b sm-m-25px-b">
      <div class="col-lg-8 col-md-10">
        <div class="section-title text-center">
          <h6 class="theme-color">Blogs</h6>
          <h2 class="dark-color font-alt">Our Latest Blog</h2>
          <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.</p>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-4 m-15px-tb">
          <div class="blog-item">
            <div class="blog-img">
              <a href="#">
                <img src="static/img/blog-img1.jpg" title="" alt="" />
              </a>
            </div>
            <div class="blog-content">
              <div class="post-meta"> OCT 15, 2018 </div>
              <h4><a class="dark-color" href="#">See Our most successful complited project.</a></h4>
              <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
                tempor incididunt ut labore et dolore magna aliqua.</p>
              <div class="btn-bar">
                <a class="btn btn-theme" href="#">Read More</a>
              </div>
            </div>
          </div>
      </div>

      <div class="col-md-4 m-15px-tb">
          <div class="blog-item">
            <div class="blog-img">
              <a href="#">
                <img src="static/img/blog-img3.jpg" title="" alt="" />
              </a>
            </div>
            <div class="blog-content">
              <div class="post-meta"> OCT 15, 2018 </div>
              <h4><a class="dark-color" href="#">See Our most successful complited project.</a></h4>
              <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
                tempor incididunt ut labore et dolore magna aliqua.</p>
              <div class="btn-bar">
                <a class="btn btn-theme" href="#">Read More</a>
              </div>
            </div>
          </div>
      </div>

      <div class="col-md-4 m-15px-tb">
          <div class="blog-item">
            <div class="blog-img">
              <a href="#">
                <img src="static/img/blog-img2.jpg" title="" alt="" />
              </a>
            </div>
            <div class="blog-content">
              <div class="post-meta"> OCT 15, 2018 </div>
              <h4><a class="dark-color" href="#">See Our most successful complited project.</a></h4>
              <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
              <div class="btn-bar">
                <a class="btn btn-theme" href="#">Read More</a>
              </div>
            </div>
          </div>
      </div>
    </div>

  </div>
</section>

<section class="section" id="team">
  <div class="container">
    <div class="row  bg-white">
      <div class="col-lg-4 md-m-30px-b">
        <div class="section-title-02">
          <h2 class="dark-color font-alt theme-after">Équipe de Direction</h2>
          <p style={{textAlign: 'justify'}}>Découvrez l'équipe de direction de Databox, composée de professionnels engagés et passionnés. Ensemble, nous définissons des stratégies innovantes pour propulser votre entreprise vers l'excellence digitale !</p>
          <div class="btn-bar">
            <a href="#contatus" class="btn btn-theme">Nous contacter</a>
          </div>
        </div>
      </div>
      <div class="col-lg-8">
        <div class="row">
          <div class="col-md-4 col-sm-6 m-30px-b">
              <div class="our-team-01">
              <div class="team-img theme-after">
                <div class="img" style={{backgroundColor: '#dfdfdf'}}>
                  <img src="static/img/alexis.png" title="" alt="" style={{backgroundColor: '#dfdfdf'}} />
                </div>
              </div>
              <div class="team-info">
                <h6 class="theme-color">Alexis ABDALA</h6>
                <p>CEO</p>
                
              </div>
            </div>
          </div>
          <div class="col-md-4 col-sm-6 m-30px-b">
            <div class="our-team-01">
              <div class="team-img theme-after">
                <div class="img">
                  <img src="static/img/garine.png" title="" alt="" style={{backgroundColor: '#dfdfdf'}} />
                </div>
              </div>
              <div class="team-info">
                <h6 class="theme-color">Garine NSONGO</h6>
                <p>CFC & AO</p>
                
              </div>
            </div>
          </div>
          
          <div class="col-md-4 col-sm-6 m-30px-b">
            <div class="our-team-01">
              <div class="team-img theme-after">
                <div class="img">
                  <img src="static/img/yvon.png" title="" alt="" style={{backgroundColor: '#dfdfdf'}} />
                </div>
              </div>
              <div class="team-info">
                <h6 class="theme-color">Yvon Flour</h6>
                <p>CTO</p>
                
              </div>
            </div>
          </div>
          <div class="col-md-4 col-sm-6 m-30px-b">
            <div class="our-team-01">
              <div class="team-img theme-after">
                <div class="img">
                  <img src="static/img/genie.png" title="" alt="" style={{backgroundColor: '#dfdfdf'}} />
                </div>
              </div>
              <div class="team-info">
                <h6 class="theme-color">Génie MAKITA</h6>
                <p>FullStack Developer</p>
                
              </div>
            </div>
          </div>
          <div class="col-md-4 col-sm-6 m-30px-b">
            <div class="our-team-01">
              <div class="team-img theme-after">
                <div class="img">
                  <img src="static/img/toussaint.png" title="" alt="" style={{backgroundColor: '#dfdfdf'}} />
                </div>
              </div>
              <div class="team-info">
                <h6 class="theme-color">Toussaint THAMBWE</h6>
                <p>Senior Mobile Developer</p>
                
              </div>
            </div>
          </div>
          <div class="col-md-4 col-sm-6 m-30px-b">
            <div class="our-team-01">
              <div class="team-img theme-after">
                <div class="img">
                  <img src="static/img/clarc.png" title="" alt=""  style={{backgroundColor: '#dfdfdf'}} />
                </div>
              </div>
              <div class="team-info">
                <h6 class="theme-color">Clarc LIEMA</h6>
                <p>Senior Designer</p>
                
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</section>

<section id="contatus" class="section">
  <div class="container">
    <div class="row justify-content-center m-45px-b sm-m-25px-b">
      <div class="col-lg-8 col-md-10">
        <div class="section-title text-center">
          <h6 class="theme-color">Contact Us</h6>
          <h2 class="dark-color font-alt">Get In Touch</h2>
          <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.</p>
        </div>
      </div>
    </div>

    <div class="row justify-content-center">
      <div class="col-lg-8 md-m-30px-b">
        <div class="contact-form">
            <h4 class="dark-color font-alt m-30px-b">Dites-nous quelque chose !</h4>
            <form class="contactform" method="post">
                <div class="row">
                  <div class="col-md-6">
                    <div class="form-group">
                      <input id="name" name="name" type="text" placeholder="Nom" class="validate form-control" required />
                      <span class="input-focus-effect theme-bg"></span>
                    </div>
                    </div>
                    <div class="col-md-6">
                    <div class="form-group">
                      <input id="email" type="email" placeholder="Email" name="email" class="validate form-control" required />
                      <span class="input-focus-effect theme-bg"></span>
                    </div>
                    </div>
                    <div class="col-md-12">
                    <div class="form-group">
                      <textarea id="message" placeholder="Message" name="message" class="form-control" required></textarea>
                      <span class="input-focus-effect theme-bg"></span>
                    </div>
                   </div>
                    <div class="col-md-12 text-center">
                      <div class="send">
                         <button class="btn btn-theme" type="submit" name="send">ENVOYER</button>
                      </div>
                      <span class="output_message"></span>
                    </div>
                  </div>
            </form>
        </div>
      </div>
      <div class="col-lg-4">
        <div class="contact-info">
            <i class="theme-color ti-location-pin"></i>
            <h6 class="dark-color font-alt">Adresse :</h6>
            <p>63 Avenue Colonel Mondjiba, Concession Cotex, Kinshasa/Gombe</p>
        </div>
        <div class="contact-info">
          <i class="theme-color ti-mobile"></i>
          <h6 class="dark-color font-alt">Téléphone :</h6>
          <p>+243 827 374 362<br/></p>
        </div>
        <div class="contact-info">
            <i class="theme-color ti-email"></i>
            <h6 class="dark-color font-alt">Email :</h6>
            <p>info@databox-tech.com</p>
        </div>
      </div>
    </div>

    
  </div>
</section>

</main>
    </>
  );
}

export default Content;
