import React from "react";
function Header() {
  return (
    <>
  <header>
    <nav class="navbar header-nav header-nav-dark navbar-expand-lg">
      <div class="container">
        <a class="navbar-brand" href="">
          <img src="static/img/logo.png" title="" alt="" width="150" />
        </a>

        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbar" aria-controls="navbar" aria-expanded="false" aria-label="Toggle navigation">
            <span></span>
            <span></span>
            <span></span>
        </button>

        <div class="collapse navbar-collapse justify-content-end" id="navbar">
          <ul class="navbar-nav ml-auto align-items-center">
            <li><a class="nav-link" href="#home">Accueil</a></li>
            <li><a class="nav-link" href="#services">Services</a></li>            
            <li><a class="nav-link" href="#feature">Réalisations</a></li>
            <li><a class="nav-link" href="#price">Partenaires</a></li>
            <li><a class="nav-link" href="#blog">Produits</a></li>
            <li><a class="nav-link" href="#team">Team</a></li>
            <li><a class="nav-link" href="#contatus">Contact</a></li>
          </ul>
        </div>
      </div>
    </nav>
  </header>
    </>
  );
}

export default Header;
